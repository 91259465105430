<style>
  .lf-fab {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    position: fixed;
    right: 10%;
    bottom: 10%;
  }
</style>

<script>
  export let loading = false;
</script>

<button class:is-loading={loading} on:click class="button lf-fab is-primary">
  <span class="icon">
    <i class="fas fa-plus" />
  </span>
</button>
