<script>
  import { componentTypes } from '/utils/ComponentModule'
  import { createSchema } from '/database';
  import { user } from '/User';
  import { goto } from '@sveltech/routify'

  let type = '';
  let emptyField = { key: undefined, value: undefined }
  let fields = [ {...emptyField} ];

  const addField = () => {
    fields = [...fields, {...emptyField}];
  }

  const convertToSchema = (tmp) => {
    let schema = { type, userId: $user.uid, fields: {} }
    tmp.map((v) => {
      schema['fields'][v.key] = v.value;
    });
    return schema;
  }

  let submitButtonDisabled = false;
  const onSubmit = async () => {
    submitButtonDisabled = true;
    const schema = convertToSchema(fields);
    await createSchema(type, schema);
    $goto('/');
  }

</script>

<style>
  .footerspace {
    padding-bottom: 15%;
  }
</style>

<div class="container footerspace">
  <h1 class="title">
    Schema Builder
  </h1>
  <form>
    <div class="field">
      <label class="label">Log Name</label>
      <div class="control">
        <input bind:value={type} class="input" type="text" placeholder="Text input">
      </div>
    </div>

    {#each fields as field, i (field)}

    <div class="field">
      <label class="label">Field</label>
      <div class='field is-grouped'>
        <p class="control is-expanded">
          <input bind:value={field.key} type="text" class="input" placeholder="Label">
        </p>
        <p class="control">
          <span class="select">
            <select bind:value={field.value}>
              {#each componentTypes as componentType, i (componentType)}
              <option value={componentType}>{componentType}</option>
              {/each}
            </select>
          </span>
        </p>
      </div>
    </div>
    {/each}
    <button on:click={addField} type="button" class="button">Add Field</button>
    <button on:click={onSubmit} disabled={submitButtonDisabled} type="button" class="button">Submit</button>
  </form>
</div>
