<script>
  import { url } from '@sveltech/routify'
  import { signOut } from '/auth'

  let burgerActive = false
  const burgerClick = () => {
    burgerActive = !burgerActive;
  }
</script>
<style>
  .transparent-bg {
    background: transparent;
  }

  .borderless {
    border: transparent;
  }
</style>

<nav class="navbar is-fixed-top is-transparent transparent-bg" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href={$url('/')}>
      <span class="icon is-large">
        <i class="fas fa-2x fa-feather"></i>
      </span>
      </a>
      <span on:click={burgerClick}
         class:is-active={burgerActive}
         role="button" 
         class="navbar-burger burger" 
         data-target="navMenu" 
         aria-label="menu" 
         aria-expanded="false">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </span>
  </div>

  <div 
    class:is-active={burgerActive}
    class="navbar-menu" 
    id="navMenu"> 

    <!--mobile hidden items-->
     <div class="navbar-end">
      <!-- navbar items -->
      <div class="navbar-item">
        <a class="navbar-item"
           href='/settings'
           >
           <span class="icon">
             <i class="fas fa-cog"></i>
           </span>
           <span>Settings</span>
        </a>
        <a class="navbar-item"
           href='/'
           on:click={signOut}>
          Log Out
        </a>
      </div>
    </div>
  </div>
</nav>


