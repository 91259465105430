<style>
  .board {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding: 60px 15% 60px 15%;
  }
</style>

<div class="board">
  <slot />
</div>
