<script>
  export let schema;
</script>

<style>
  .logger {
    border: 1px solid #dadce0;
    border-radius: 5px;
    background-color: white;
    padding: 1rem;

    display: flex;
    flex-basis: auto;
    align-items: center;
    justify-content: center;

    width: 250px;
    height: 250px;
    margin: 10px;
  }

  .logger:hover {
    background-color: #e8f0ff;
  }

  .logger:active {
    background-color: #cfe0ff;
  }
</style>

<div on:click class='card logger'>
  <h1>{schema.type}</h1>
</div>
