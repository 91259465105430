<script>
  import { GoogleSignInButton, Pageloader } from '/components';
  import { user } from '/User';
</script>

<style>
  .login-container {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .login-form {
    align-items: center;
    flex-content: center;
    margin: auto;
  }

  .logo-spacing {
    padding: 20%;
  }
</style>

{#if $user.loading}
  <Pageloader/>
{:else}
  <div class='login-container'>
    <div class='login-form hero-body has-text-centered column is-4'>
      <div class="box">
        <div class='field logo-spacing'>
          <span class="icon is-large">
            <i class="fas fa-6x fa-feather"></i>
          </span>
        </div>
        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input class="input" type="email" placeholder="Email">
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
            <span class="icon is-small is-right">
              <i class="fas fa-check"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input class="input" type="password" placeholder="Password">
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control">
            <button class="button is-primary is-fullwidth">Login</button>
          </p>
        </div>
        <div class='field'>
          <p class="control">
            <GoogleSignInButton/>
          </p>
        </div>
      </div>
    </div>
  </div>
{/if}
