<style>
  .full-screen {
    width: 100%;
    height: 100%;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

<div class="full-screen center pageloader">
  <p>Loading...</p>
</div>
